<script setup>
	import $ from 'jquery'
	import { ref } from 'vue';
	import { Notify } from 'quasar'
	import axios from 'axios';

	const API_URL = 'https://jira.idata.hu/rest/api/2/issue/';
	const noIntervalFilterJiraKeys = ['META-415', 'META-421']; // Szabadság, Betegszabadság

	const token = ref(false);
	const tickets = ref(false);
	const deleteDialog = ref(false);

	let selectedKey = ref('');
	let logWorkHoursInput = ref('');
	let descriptionInput = ref('');
	let logWorkStartTimeInput = ref('');
	let newJiraKey = ref('');
	let newJiraName = ref('');
	let newJiraLogWork = ref('');
	let newJiraDescription = ref('');
	let newJiraStarttime = ref('');
	let jiraTickets = ref([]);
	let jiraToken = ref('');
	let email = ref('');
	let jiraTokenExistingInLocalStorage = ref(false);
	let selectedInterval = ref({});
	let dateSelectionInPrgoress = false;
	let loggedWorksCalendar = ref([]);
	let deleteDay;
	let enabledDateBegin = ref(new Date());
	let enabledDateEnd = ref(new Date());

	function testApi() {
		const headers = { headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jiraToken.value}`,
			}
		}
		axios.get(`${API_URL}META-320/worklog`, headers).then(loggedWorks => {
			let connection = loggedWorks.data.worklogs.length > 0;
			console.log("Test: " + connection);
			Notify.create({type: 'positive', message: 'Successful connection'});
		}).catch(error => {
			if (error.response.status == 401) {
				Notify.create({type: 'negative', message: 'Wrong jira token!'});
			} else {
				alert("Error code: " + error.response.status);
			}
			console.error('Error get log work:', error);
		});
	}

	function loadFromLocalStorage() {
		if (!localStorage.getItem("jiraTicketsLocalStorage")) {
			localStorage.setItem("jiraTicketsLocalStorage", JSON.stringify([]));
		}
		const jiraTokenLocalStorage = localStorage.getItem("jiraToken");
		if (jiraTokenLocalStorage !== null) {
			jiraToken.value = jiraTokenLocalStorage;
			email.value = localStorage.getItem("email");
		}
		jiraTokenExistingInLocalStorage.value = (localStorage.getItem("jiraToken") !== null);
		loadSelects();
	}

	function loadSelects() {
		let jiraTicketsLocalStorage = JSON.parse(localStorage.getItem("jiraTicketsLocalStorage"));
		jiraTickets.value = jiraTicketsLocalStorage;
	}

	function saveToLocalStorage() {
		localStorage.setItem("jiraToken", jiraToken.value);
		localStorage.setItem("email", email.value);
		jiraTokenExistingInLocalStorage.value = (localStorage.getItem("jiraToken") !== null)
		Notify.create({type: 'positive', message: 'Successfully saving'});
		if (jiraTickets.value.length > 0) {
			loadSelects();
			updateLoggedWorksCalendar();
		}
	}

	function addJiraTicket() {
		if (newJiraKey.value == "" || newJiraName.value == "") {
			Notify.create({type: 'negative', message: 'Key and name field can not be empty!'});
			return;
		}

		if (newJiraKey.value) {
			let randomIdLength = 6;
			let randomId = Math.random().toString(36).substring(2, randomIdLength + 2)
			let newJiraTicket = { id: randomId, key: newJiraKey.value, name: newJiraName.value, logWork: newJiraLogWork.value, description: newJiraDescription.value, starttime: newJiraStarttime.value };
			let jiraTicketsLocalStorage = JSON.parse(localStorage.getItem("jiraTicketsLocalStorage"));
			if (jiraTicketsLocalStorage == null) {
				jiraTicketsLocalStorage = [];
			}
			jiraTicketsLocalStorage.push(newJiraTicket);
			localStorage.setItem("jiraTicketsLocalStorage", JSON.stringify(jiraTicketsLocalStorage));
			loadSelects();

			if (jiraTickets.value.length == 1) {
				selectedKey.value = randomId;
			}

			selectedKeyChange();
			newJiraKey.value = "";
			newJiraName.value = "";
			newJiraLogWork.value = "";
			newJiraDescription.value = "";
			newJiraStarttime.value = "";
		}
	}

	function deleteJiraTicket(jiraIdToDelete) {
		if (jiraIdToDelete) {
			let jiraTicketsLocalStorage = JSON.parse(localStorage.getItem("jiraTicketsLocalStorage"));
			let jiraTicketsLocalStorageAfterDelete = $.grep(jiraTicketsLocalStorage, function(jiraTicketLocalStorage) {
				return jiraIdToDelete.indexOf(jiraTicketLocalStorage.id) < 0;
			});
			localStorage.setItem("jiraTicketsLocalStorage", JSON.stringify(jiraTicketsLocalStorageAfterDelete));
			loadSelects();
		}
	}

	function selectedKeyChange() {
		let jiraTicketLocalStorage = $.grep(JSON.parse(localStorage.getItem("jiraTicketsLocalStorage")), function(jiraTicketLocalStorage) {
			return selectedKey.value.indexOf(jiraTicketLocalStorage.id) > -1;
		});
		if (jiraTicketLocalStorage.length > 0) {
			logWorkHoursInput.value = jiraTicketLocalStorage[0].logWork;
			descriptionInput.value = jiraTicketLocalStorage[0].description;
			logWorkStartTimeInput.value = jiraTicketLocalStorage[0].starttime;
			if (noIntervalFilterJiraKeys.includes(jiraTicketLocalStorage[0].key))  {
				console.log("noIntervalFilterJiraKeys");
				enabledDateBegin.value = new Date().setDate(new Date().getDate() - 31);
				enabledDateEnd.value = new Date().setDate(new Date().getDate() + 31);
			} else {
				enabledDateBegin.value = new Date().setDate(new Date().getDate() - 9);
				enabledDateEnd.value = new Date();
			}
		}
		updateLoggedWorksCalendar();
	}

	function logWork() {
		if (dateSelectionInPrgoress) {
			Notify.create({type: 'negative', message: 'Only the start day of the selected date was given, please also give the end date!'});
			return;
		}
		console.log("logWork: " + selectedInterval.value.start + " - " + selectedInterval.value.end);
		if (logWorkHoursInput.value == "") {
			Notify.create({type: 'negative', message: 'Time spent field can not be empty!'});
			return;
		}

		for (let d = new Date(selectedInterval.value.start); d <= new Date(selectedInterval.value.end); d.setDate(d.getDate() + 1)) {
			let time = (logWorkStartTimeInput.value != "" ? logWorkStartTimeInput.value : "12:00");
			let tzo = new Date(d).getTimezoneOffset();
			let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "T" + time + ":00.000+0" + Math.floor(Math.abs(tzo) / 60) + "00";
			addLogWork(date);
		}
	}

	function addLogWork(date) {
		console.log("addLogWork: " + date);
		const headers = { headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jiraToken.value}`,
			}
		}
		const jiraApiUrl = `${API_URL}${idToKey(selectedKey.value)}/worklog`;
		const worklogData = {
			"comment": descriptionInput.value,
			"started": date,
			"timeSpentSeconds": 60 * 60 * logWorkHoursInput.value
		};
		axios.post(jiraApiUrl, worklogData, headers)
			.then(response => {
				console.log('Log work successfully added:', response.data);
				updateLoggedWorksCalendar();
				Notify.create({type: 'positive', message: 'Log work successfully added'});
			})
			.catch(error => {
				console.error('Error deleting log work:', error);
			});
	}

	function idToKey(id) {
		let jiraTicket = $.grep(JSON.parse(localStorage.getItem("jiraTicketsLocalStorage")), function(jiraTicketLocalStorage) {
			return id.indexOf(jiraTicketLocalStorage.id) > -1;
		});
		if (jiraTicket.length > 0) {
			return jiraTicket[0].key;
		}
		return null;
	}

	function updateLoggedWorksCalendar() {
		console.log("updateLoggedWorksCalendar");
		const headers = { headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jiraToken.value}`,
			}
		}
		loggedWorksCalendar.value = [];
		axios.get(`${API_URL}${idToKey(selectedKey.value)}/worklog`, headers).then(loggedWorks => {
			let userWorklogs = $.grep(loggedWorks.data.worklogs, function(worklog) {
				return email.value.indexOf(worklog.author.emailAddress) > -1;
			});
			let loggedDates = [];
			$.each(userWorklogs, function(key, value) {
				loggedDates.push({
					highlight: 'red',
					jiraid: value.id,
					dates: new Date(value.started),
					popover: { label: value.timeSpent + (value.comment ? " - " + value.comment : "") }
				});
			});
			loggedWorksCalendar.value = loggedDates;
		}).catch(error => {
			if (error.response.status == 404) {
				Notify.create({type: 'negative', message: 'Jira key doesn\'t exists: ' + idToKey(selectedKey.value)} );
			} else {
				Notify.create({type: 'negative', message: 'Error code: ' + error.response.status + ' | Possible your jira token is wrong'} );
			}
			console.error('Error get log work:', error);
		});
	}

	function initDateSelector() {
		selectedInterval.value.start = new Date();
		selectedInterval.value.end = new Date();
	}

	function dayclicked(day) {
		if (day.attributes[0]) {
			deleteDay = day;
			deleteDialog.value = true;
		}
	}

	function selectedDayClicked() {
		dateSelectionInPrgoress = !dateSelectionInPrgoress;
	}

	function deleteLoggedWorkSelectedDay() {
		for (const deleteAttribute of deleteDay.attributes) {
			deleteLoggedWork(deleteAttribute.jiraid);
		}
	}

	function deleteLoggedWork(jiraid) {
		const jiraApiUrl = `${API_URL}${idToKey(selectedKey.value)}/worklog/${jiraid}`;
		axios.delete(jiraApiUrl, {
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jiraToken.value}`,
			},
		})
		.then(response => {
			console.log('Log work successfully deleted');
			console.log(response);
			Notify.create({type: 'positive', message: 'Log work successfully deleted'});
			updateLoggedWorksCalendar();
		})
		.catch(error => {
			console.error('Error delete log work:', error);
		});
	}


	function deleteFromLocalStorage() {
		jiraToken.value = "";
		email.value = "";
		localStorage.removeItem("jiraToken");
		localStorage.removeItem("email");
		jiraTokenExistingInLocalStorage.value = false;
		Notify.create({type: 'positive', message: 'Successfully deleted'});
	}

	function onLoad() {
		document.title = "Logwork";
		loadFromLocalStorage();
		initDateSelector();
		if (jiraTokenExistingInLocalStorage.value && jiraTickets.value.length > 0) {
			selectedKey.value = jiraTickets.value[0].id;
			selectedKeyChange();
		}
	}

	onLoad();
</script>

<style>
	.custom-select {
		min-width: 350px;
		position: relative;
	}

	select {
		appearance: none;
		/*  safari  */
		-webkit-appearance: none;
		/*  other styles for aesthetics */
		width: 100%;
		font-size: 1.15rem;
		padding: 0.675em 6em 0.675em 1em;
		background-color: #fff;
		border: 1px solid #caced1;
		border-radius: 0.25rem;
		color: #000;
		cursor: pointer;
	}

	.custom-select::before,
	.custom-select::after {
		--size: 0.3rem;
		content: "";
		position: absolute;
		right: 1rem;
		pointer-events: none;
	}

	.custom-select::before {
		border-left: var(--size) solid transparent;
		border-right: var(--size) solid transparent;
		border-bottom: var(--size) solid black;
		top: 40%;
	}

	.custom-select::after {
		border-left: var(--size) solid transparent;
		border-right: var(--size) solid transparent;
		border-top: var(--size) solid black;
		top: 55%;
	}

	* {
		box-sizing: border-box;
	}

	body {
		#min-height: 100vh;
		display: grid;
		place-items: center;
		font-size: 20px !important;
	}

	.q-notification {
		font-size: 20px !important;
	}

	.on-left, .on-right {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.q-dialog__inner--minimized > div {
		max-width: none !important;
	}

	/* To fix popup window bug: when you open a popup window the whole body move down some pixel */
	body.q-body--prevent-scroll {
		position: relative !important;
	}

    input {
		width: 100%;
    }
</style>

<template>
	<div id="app">
		<div class="q-pa-md q-gutter-sm">
			<q-btn label="Token" color="primary" @click="token = true" />
			<q-btn label="Tickets" color="primary" @click="tickets = true" />

			<q-dialog v-model="token">
			<q-card>
				<q-card-section>
					<div class="text-h4">Jira token and e-mail</div><br/>
					<p><a target="_blank" href="https://jira.idata.hu/secure/ViewProfile.jspa?selectedTab=com.atlassian.pats.pats-plugin:jira-user-personal-access-tokens">Here you can create your Personal Access Token</a> (it is only two clicks)</p>
					<p><a target="_blank" href="https://jira.idata.hu/secure/ViewProfile.jspa?selectedTab=jira.user.profile.panels:user-profile-summary-panel">Here you can get your e-mail address from jira</a></p>
				</q-card-section>

				<q-card-section class="q-pt-none">
					<q-input v-model="jiraToken" label="Jira token" />
					<q-input v-model="email" label="Email" /><br/>
					<q-btn label="Save" color="primary" @click="saveToLocalStorage" />&nbsp;
					<q-btn label="Delete" color="primary" @click="deleteFromLocalStorage" />&nbsp;
					<q-btn label="Test" color="primary" @click="testApi" />
				</q-card-section>

				<q-card-actions align="right">
				<q-btn flat label="Close" color="primary" v-close-popup />
				</q-card-actions>
			</q-card>
			</q-dialog>

			<q-dialog v-model="tickets">
			<q-card>
				<q-card-section>
				<div class="text-h4">Manage jira tickets</div>
				</q-card-section>

				<q-separator />

				<q-card-section class="q-pt-none">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>Key *</th>
								<th>Name *</th>
								<th>Default log work (h)</th>
								<th>Default description</th>
								<th>Default start time</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						<tr>
							<td><input type="text" class="input-group-field" v-model="newJiraKey" @keyup.enter="addJiraTicket" placeholder="META-369" /></td>
							<td><input type="text" class="input-group-field" v-model="newJiraName" @keyup.enter="addJiraTicket" placeholder="Információcsere" /></td>
							<td><input type="number" class="input-group-field" v-model="newJiraLogWork" @keyup.enter="addJiraTicket" placeholder="0.5" maxlength="4" size="3" /></td>
							<td><input type="text" class="input-group-field" v-model="newJiraDescription" @keyup.enter="addJiraTicket" placeholder="E-mailek kezelése" /></td>
							<td><input type="text" class="input-group-field" v-model="newJiraStarttime" @keyup.enter="addJiraTicket" maxlength="5" size="4" placeholder="15:00" /></td>
							<td><q-btn label="Add" color="primary" @click="addJiraTicket" /></td>
						</tr>
						<tr>
							<td colspan="6"><hr/></td>
						</tr>
						<tr v-for="jiraTicket in jiraTickets" :key="jiraTicket.key">
							<td>{{ jiraTicket.key }}</td><td>{{ jiraTicket.name }}</td><td>{{ jiraTicket.logWork }}</td><td>{{ jiraTicket.description }}</td><td>{{ jiraTicket.starttime }}</td><td><q-btn label="Del" color="primary" @click="deleteJiraTicket(jiraTicket.id)" /></td>
						</tr>
						</tbody>
					</table>
				</q-card-section>
					&nbsp; * mandatory fields
				<q-separator />

				<q-card-actions align="right">
				<q-btn flat label="Close" color="primary" v-close-popup />
				</q-card-actions>
			</q-card>
			</q-dialog>

			<q-dialog v-model="deleteDialog">
			<q-card>
				<q-card-section>
					<div class="text-h6">Do you really want to delete logged work?</div>
				</q-card-section>

				<q-separator />

				<q-card-actions align="center">
					<q-btn flat label="Yes" color="primary" @click="deleteLoggedWorkSelectedDay()" v-close-popup />
					<q-btn flat label="No" color="primary" v-close-popup />
				</q-card-actions>
			</q-card>
			</q-dialog>

		</div>

		<div v-if="!jiraTokenExistingInLocalStorage">
			<h4>Setup your jira token and e-mail address in the TOKEN menu</h4>
		</div>
		<div v-if="jiraTickets.length == 0">
			<h4>Add some jira tickets in the TICKETS menu</h4>
		</div>
		<div v-if="jiraTokenExistingInLocalStorage && jiraTickets.length > 0">
			<h4>Selected jira ticket</h4>
			<div class="custom-select">
				<select id="jiraTicketSelector" v-model="selectedKey" @change="selectedKeyChange">
					<option v-for="option in jiraTickets" :value="option.id" :key="option.key" >{{ option.name }} ({{option.key}})</option>
				</select><br/>
			</div>
			<hr/>
			<h5>Jump to jira ticket: <a :href="'https://jira.idata.hu/browse/' + idToKey(selectedKey)" target="_blank">{{idToKey(selectedKey)}}</a></h5>
			<VCalendar :locale="{ firstDayOfWeek: 2 }"  @dayclick="dayclicked" :attributes="loggedWorksCalendar" />&nbsp;
			<VDatePicker :locale="{ firstDayOfWeek: 2 }" :min-date="enabledDateBegin" :max-date="enabledDateEnd" v-model.range="selectedInterval" @dayclick="selectedDayClicked" /><br/><br/>
			<p>(Red circle click --> delete logged work)</p>
			<q-separator /><br/>
			Time spent (h)<br/><input type="number" v-model="logWorkHoursInput" min="0" max="10"><br/><br/>
			Description<input v-model="descriptionInput" type="text" /><br/><br/>
			Start time<input v-model="logWorkStartTimeInput" maxlength="5" size="4" type="text" /><br/><br/>
			<q-btn label="Log work" color="primary" @click="logWork" /><br/><br/>
		</div>
	</div>
</template>
